import { useNavigation } from "../providers/NavigationProvider";

export const Welcome = () => {
  const { navigate } = useNavigation();
  return (
    <>
      <h2>THINK YOU KNOW CIN?</h2>
      <p>
        Test your knowledge against your peers when it comes to
        chemotherapy-induced neutropenia (CIN), the many risks both patients and
        physicians face every day—and the consequences of doing nothing until
        it’s too late. After you’re done, look for your standing displayed
        within the booth.
      </p>
      <button onClick={() => navigate("signin")}>Start now</button>
    </>
  );
};

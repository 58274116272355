import { Leaderboard } from './components/Leaderboard';
import { References } from './components/References';
import { Congrats } from './pages/Congrats';
import { Quiz } from './pages/Quiz';
import { SignIn } from './pages/SignIn';
import { Welcome } from './pages/Welcome';
import { Providers } from './providers';
import { NavigationContext } from './providers/NavigationProvider';
import Menu from "./components/Menu";
import React from "react";
import ContactsMenu from "./components/ContactsMenu";
import Footer from "./components/Footer";

function App() {
  return (
    <Providers>
      <div className="background-wrap">
        <div className="page">
          <ContactsMenu />
          <Menu />
          <div className="main-area container">
            <h1 className="page-title">#CINSIGHTS</h1>
            <main className="main">
              <div className="content">
                <NavigationContext.Consumer>
                  {({ page }) => {
                    switch (page) {
                      case 'signin':
                        return <SignIn />;
                      case 'quiz':
                        return <Quiz />;
                      case 'congrats':
                        return <Congrats />;
                      default:
                        return <Welcome />;
                    }
                  }}
                </NavigationContext.Consumer>
              </div>
              <Leaderboard />
            </main>
            <References />
          </div>
        </div>
      <Footer />
    </div>
  </Providers>
  );
}

export default App;

import firebase from 'firebase/app';

import 'firebase/firestore';
// import 'firebase/analytics';


const firebaseConfig = {
  apiKey: 'AIzaSyDDNbiVLYP796oE1B_RpOL1eQfFu2awRDU',
  authDomain: 'cin-survey.firebaseapp.com',
  databaseURL: 'https://cin-survey.firebaseio.com',
  projectId: 'cin-survey',
  storageBucket: 'cin-survey.appspot.com',
  messagingSenderId: '98717390145',
  appId: '1:98717390145:web:772972f849f98fa3843dc0',
  measurementId: 'G-LH9XJHYR41',
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();
const db = firebase.firestore();
const now = firebase.firestore.Timestamp.now;


if (window.location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080);
}

export { db, now, firebase };

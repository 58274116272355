import { useMemo } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useUser } from '../providers/UserProvider';
import { db } from '../utils/firebase';

export const Leaderboard = () => {
  const { user } = useUser();
  const [leaders = []] = useCollectionData(
    db.collection('users').orderBy('score', 'desc').limit(10)
  );

  const userInLeaderboard = useMemo(
    () => !!user && leaders.some((l) => l.email === user.email),
    [user, leaders]
  );

  return (
    <aside className="side">
      <h3>Leaderboard</h3>
      <ul className="leaderboard">
        {leaders.map(({ email, username, score }, index) => (
          <li className="leaderboard-item" key={email}>
            <span className="leaderboard-item__name">
              {index + 1}. {username || email}
            </span>
            <span className="leaderboard-item__score">{score}%</span>
          </li>
        ))}
        {!!user && !userInLeaderboard && (
          <li className="leaderboard-item leaderboard-item--footer">
            <span className="leaderboard-item__name">
              {user.username || user.email}
            </span>
            <span className="leaderboard-item__score">{user.score}%</span>
          </li>
        )}
      </ul>
    </aside>
  );
};

import classNames from "classnames";
import { useState } from "react";

export const Question = ({ question, number, onSave, onNext }) => {
  const [selected, setSelected] = useState();
  const [viewMode, setViewMode] = useState("question");

  return (
    <div className="quiz">
      <div
        className="quiz__question"
        dangerouslySetInnerHTML={{ __html: `${number}. ${question.question}` }}
      ></div>
      <div className="quiz__options">
        {question.options.map((option, index) => (
          <label
            key={index}
            className={classNames("quiz__option", {
              "quiz__option--checked": index === selected,
              "quiz__option--correct":
                viewMode === "answer" && index === question.correct,
              "quiz__option--incorrect":
                viewMode === "answer" &&
                index === selected &&
                selected !== question.correct,
            })}
          >
            <input
              type="radio"
              name="option"
              disabled={viewMode !== "question"}
              checked={selected === index}
              onChange={() => setSelected(index)}
            />
            {String.fromCharCode(65 + index)}. {option}
          </label>
        ))}
      </div>
      {viewMode === "answer" && (
        <div
          className="quiz__reference"
          dangerouslySetInnerHTML={{ __html: question.reference }}
        />
      )}
      <button
        type="button"
        className="quiz__submit"
        onClick={async () => {
          if (viewMode === "question") {
            setViewMode("answer");
            await onSave(selected);
          } else {
            setSelected(null);
            setViewMode("question");
            await onNext();
          }
        }}
      >
        {viewMode === "question"
          ? "Submit"
          : number % 5 === 0
          ? "See your results"
          : "Next question"}
      </button>
    </div>
  );
};

import { NavigationProvider } from './NavigationProvider';
import { QuizProvider } from './QuizProvider';
import { UserProvider } from './UserProvider';

export const Providers = ({ children }) => (
  <NavigationProvider>
    <UserProvider>
      <QuizProvider>{children}</QuizProvider>
    </UserProvider>
  </NavigationProvider>
);

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { db } from '../utils/firebase';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [userRef, setUserRef] = useState();
  const [user, userLoading] = useDocumentData(userRef, {
    idField: 'id',
  });

  const signIn = useCallback(
    async ({ email, username }) => {
      const existingUsers = await db
        .collection('users')
        .where('email', '==', email)
        .get();
      let dbUser;
      if (existingUsers.size) {
        dbUser = existingUsers.docs[0].ref;
        await dbUser.update({ username });
      } else {
        dbUser = await db.collection('users').add({ email, username });
      }
      setUserRef(dbUser);
    },
    [setUserRef]
  );

  const resetUser = useCallback(() => {
    setUserRef(null);
  }, [setUserRef]);

  const contextValue = useMemo(
    () => ({
      userRef,
      user,
      userLoading,
      signIn,
      resetUser,
    }),
    [user, userLoading, userRef, signIn, resetUser]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

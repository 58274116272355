import { useEffect, useState } from 'react';
import { Question } from '../components/Question';
import { questions } from '../config/questions';
import { useNavigation } from '../providers/NavigationProvider';
import { useQuiz } from '../providers/QuizProvider';

export const Quiz = () => {
  const { sessionData, sessionLoading, addAnswer } = useQuiz();
  const { navigate } = useNavigation();
  const [activeQuestion, setActiveQuestion] = useState();

  useEffect(() => {
    if (typeof activeQuestion === 'undefined' && sessionData) {
      setActiveQuestion(sessionData.answers?.length || 0);
    }
  }, [activeQuestion, sessionData, setActiveQuestion]);

  if (!sessionData || sessionLoading || !questions[activeQuestion]) {
    return <div>Please wait...</div>;
  }

  const question = questions[activeQuestion];

  return (
    <Question
      question={question}
      number={activeQuestion + 1}
      onSave={(selected) => {
        const answer = {
          answer: selected + 1,
          correct: selected === question.correct,
          index: activeQuestion,
        };
        return addAnswer(answer);
      }}
      onNext={() => {
        if (
          activeQuestion + 1 === questions.length ||
          (activeQuestion + 1) % 5 === 0
        ) {
          navigate('congrats');
        } else if (activeQuestion + 1 < questions.length) {
          setActiveQuestion(activeQuestion + 1);
        }
      }}
    />
  );
};

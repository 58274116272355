import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { questions } from '../config/questions';
import { now, firebase } from '../utils/firebase';
import { useUser } from './UserProvider';

const points = 100 / questions.length;

export const QuizContext = createContext({});

export const QuizProvider = ({ children }) => {
  const { userRef, user } = useUser();

  const [sessionRef, setSessionRef] = useState();
  const [sessionData, sessionLoading] = useDocumentData(sessionRef, {
    idField: 'id',
  });

  const createSession = useCallback(async () => {
    setSessionRef(
      await userRef.collection('sessions').add({ started: now(), answers: [] })
    );
  }, [userRef, setSessionRef]);

  const loadSession = useCallback(async () => {
    if (!userRef) {
      return;
    }
    const lastSessionsSnap = await userRef
      .collection('sessions')
      .orderBy('started', 'desc')
      .limit(1)
      .get();
    if (lastSessionsSnap.size) {
      const doc = lastSessionsSnap.docs[0];
      // there is a session and there is un-answered questions yet
      if (doc.get('answers').length < questions.length) {
        setSessionRef(doc.ref);
        return;
      }
    }
    createSession();
  }, [userRef, setSessionRef, createSession]);

  // load last session on sign in (userRef change).
  useEffect(() => {
    loadSession();
  }, [loadSession]);

  const sessionScore = useMemo(
    () =>
      Math.round(
        sessionData?.answers?.filter((i) => i.correct).length * points
      ),
    [sessionData?.answers]
  );

  useEffect(() => {
    if (userRef && user && (!user.score || sessionScore > user.score)) {
      userRef.update({ score: sessionScore });
    }
  }, [sessionScore, user, userRef]);

  const addAnswer = useCallback(
    async (answer) => {
      if (!sessionRef) {
        console.error('Saving answer for non existing session');
        return;
      }
      await sessionRef.update({
        answers: firebase.firestore.FieldValue.arrayUnion(answer),
      });
    },
    [sessionRef]
  );

  const resetQuiz = useCallback(() => {
    setSessionRef(null);
  }, [setSessionRef]);

  const contextValue = useMemo(
    () => ({
      createSession,
      sessionRef,
      sessionData,
      sessionScore,
      sessionLoading,
      addAnswer,
      resetQuiz,
    }),
    [
      createSession,
      sessionRef,
      sessionData,
      sessionScore,
      sessionLoading,
      addAnswer,
      resetQuiz,
    ]
  );

  return (
    <QuizContext.Provider value={contextValue}>{children}</QuizContext.Provider>
  );
};

export const useQuiz = () => useContext(QuizContext);

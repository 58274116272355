import React from "react";
import { mainWebsiteUrl } from "../config/config";

const Menu = () => {
  return (
    <header className="main-menu">
      <div className="clearfix">
        <div className="logo_container">
          <span className="logo_helper" />
          <a href={mainWebsiteUrl}>
            <img
              src={`${mainWebsiteUrl}/wp-content/uploads/2021/01/CINRisk_logo.png`}
              alt="Chemotherapy-Induced Neutropenia"
              id="logo"
            />
          </a>
        </div>
        <div>
          <ul>
            <li>
              <a href={`${mainWebsiteUrl}/mechanism-of-disease/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/mechanism_mobile_beige.png`}
                  alt=""
                />
                <span> Mechanism of Disease</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/risk-factors/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/risk_mobile_beige.png`}
                  alt=""
                />
                <span>Risk Factors</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/disease-magnitude/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/magnitude_mobile_beige.png`}
                  alt=""
                />
                <span> Disease Magnitude</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/disrupted-outcomes/`}>
                <img
                  src={`
                    ${mainWebsiteUrl}/wp-content/uploads/2020/11/outcomes_mobile_beige.png`}
                  alt=""
                />
                <span>Disrupted outcomes</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/neutropenia-vulnerability-gap/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2021/06/nvg_icon_beige.png`}
                  alt=""
                />
                <span>Neutropenia Vulnerability Gap</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/cost-resource-burden/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/cost_mobile_beige.png`}
                  alt=""
                />
                <span>Cost/Resource Burden</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/need-for-innovation/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/innovation_mobile_beige.png`}
                  alt=""
                />
                <span> Need for Innovation</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/resources/`}>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/11/resource_mobile_beige.png`}
                  alt=""
                />
                <span>Resources</span>
              </a>
            </li>
            <li>
              <a href={`${mainWebsiteUrl}/resources/`}>
                <span> HEAR INSIGHTS FROM DR. LYMAN</span>
                <img
                  src={`${mainWebsiteUrl}/wp-content/uploads/2020/12/KOL_Play_Icon.png`}
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
export default Menu;

import React, { useState } from "react";
import { mainWebsiteUrl } from "../config/config";

const ContactsMenu = () => {
  const [visible, setVisible] = useState(false);
  const menuClass = visible ? "visible" : "";
  return (
    <div className="contacts-menu">
      <div className="menu-container">
        <div className="mobile-header">
          <img
            src={`${mainWebsiteUrl}/wp-content/uploads/2021/01/CINRisk_logo.png`}
            alt="Chemotherapy-Induced Neutropenia"
          />
          <div
            className="mobile-menu-bar-toggle"
            onClick={() => setVisible(!visible)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"contacts-menu-inner " + menuClass}>
          <div className="left-side">
            <a href={`${mainWebsiteUrl}/contact-us`}>Contact Us</a>
          </div>
          <div className="divider">|</div>
          <div className="right-side">
            <a href={`${mainWebsiteUrl}/contact-us#subscribe`}>
              Subscribe for Updates
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsMenu;

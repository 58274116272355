import React from "react";
import { mainWebsiteUrl } from "../config/config";

const Footer = () => {
  return (
    <div className="footer w-100">
      <div className="footer-wrap">
        <div className="terms-and-privacy">
          <a className="terms" href={`${mainWebsiteUrl}/terms-of-use/`}>
            TERMS OF USE
          </a>
          <span className="divider">|</span>
          <a className="terms" href={`${mainWebsiteUrl}/privacy-policy/`}>
            PRIVACY POLICY
          </a>
        </div>
        <div className="social-links">
          <a
            className="fb-link"
            href="https://www.facebook.com/CINRisk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            className="in-link"
            href="https://www.linkedin.com/search/results/all/?keywords=%23cinrisk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
          <a
            className="twt-link"
            href="https://twitter.com/CinRisk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
        </div>

        <div className="footer-logo-copy-rights">
          <div className="col-left">
            <img
              src={`${mainWebsiteUrl}//wp-content/uploads/2020/11/BeyondSpringPharma_Logo.svg`}
              alt=""
              title="BeyondSpringPharma_Logo"
            />
          </div>
          <div className="col-right copy-rights-text">
            © 2021 BeyondSpring Inc. All rights reserved. BYSI-2011021
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

import { useQuiz } from "../providers/QuizProvider";

export const References = () => {
  const { sessionData } = useQuiz();

  if (!sessionData) {
    return null;
  }

  return (
    <div className="references">
      <h2>References</h2>
      <p>
        BeyondSpring, Inc. Buying Process/Patient Journey in CIN Market
        Research, November 2020.
      </p>

      <p>
        Bodey GP, Buckley M, Sathe YS, Freireich EJ. Quantitative relationships
        between circulating leukocytes and infection in patients with acute
        leukemia. <i>Ann Intern Med.</i> 1966;64(2):328-340.
      </p>

      <p>
        Buckley SA, Othus M, Vainstein V, Abkowitz JL, Estey EH, Walter RB.
        Prediction of adverse events during intensive induction chemotherapy for
        acute myeloid leukemia or high-grade myelodysplastic syndromes.{" "}
        <i>Am J Hematol.</i> 2014;89(4):423-428.
      </p>

      <p>
        Burris HA, Belani C, Kaufman PA, et al. Pegfilgrastim on the same day
        versus next day of chemotherapy in patients with breast cancer,
        non–small-cell lung cancer, ovarian cancer, and non-Hodgkin’s lymphoma:
        results of four multicenter, double-blind, randomized phase II studies.{" "}
        <i>J Oncol Pract.</i> 2010;6(3):133-140.
      </p>

      <p>
        Centers for Disease Control and Prevention. Neutropenia and risk of
        infections. What you need to know. Centers for Disease Control and
        Prevention website. Accessed November 9, 2020.
        <br />
        <a
          href="https://www.cdc.gov/cancer/preventinfections/pdf/neutropenia.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.cdc.gov/cancer/preventinfections/pdf/neutropenia.pdf
        </a>
      </p>

      <p>
        Cheng C, Gallagher EM, Yeh JY, Earl MA. Rates of febrile neutropenia
        with pegfilgrastim on same day versus next day of CHOP with or without
        rituximab. <i>Anticancer Drugs.</i> 2014;25(8):964-969.
      </p>

      <p>
        Crawford J, Dale DC, Lyman GH. Chemotherapy-induced neutropenia: risks,
        consequences, and new directions for its management. <i>Cancer.</i>{" "}
        2004;100(2):228-237.
      </p>

      <p>
        Crawford J, Denduluri N, Patt D, et al. Relative dose intensity of
        first-line chemotherapy and overall survival in patients with advanced
        non-small-cell lung cancer. <i>Support Care Cancer.</i>{" "}
        2020;28(2):925-932.
      </p>

      <p>
        Dale, DC. How I diagnose and treat neutropenia.{" "}
        <i>Curr Opin Hematol.</i> 2016;23(1):1-4.
      </p>

      <p>
        Halpern MT, Yabroff KR. Prevalence of outpatient cancer treatment in the
        United States: estimates from the Medical Panel Expenditures Survey
        (MEPS). <i>Cancer Invest.</i> 2008;26(6):647-651
      </p>

      <p>
        Healthcare Cost and Utilization Project (HCUP). 2017. Agency for
        Healthcare Research and Quality, Rockville, MD. Accessed November 13,
        2020.
        <br />
        <a href="https://hcupnet.ahrq.gov" target="_blank" rel="noreferrer">
          https://hcupnet.ahrq.gov
        </a>
      </p>

      <p>
        Lee J, Lee JE, Kim Z, et al. Pegfilgrastim for primary prophylaxis of
        febrile neutropenia in breast cancer patients undergoing TAC
        chemotherapy. <i>Ann Surg Treat Res.</i> 2018;94(5):223-228.
      </p>

      <p>
        Li Y, Klippel Z, Shih X, Wang H, Reiner M, Page JH. Trajectory of
        absolute neutrophil counts in patients treated with pegfilgrastim on the
        day of chemotherapy versus the day after chemotherapy.{" "}
        <i>Cancer Chemother Pharmacol.</i> 2016;77(4):703-712.
      </p>

      <p>
        Lyman GH. Impact of chemotherapy dose intensity on cancer patient
        outcomes. <i>J Natl Compr Canc Netw.</i> 2009;7(1):99-108.
      </p>

      <p>
        Masuda N, Tokuda Y, Nakamura S, Shimazaki R, Ito Y, Tamura K. Dose
        response of pegfilgrastim in Japanese breast cancer patients receiving
        six cycles of docetaxel, doxorubicin, and cyclophosphamide therapy: a
        randomized controlled trial. <i>Support Care Cancer.</i>{" "}
        2015;23(10):2891-2898.
      </p>

      <p>
        Neulasta (pegfilgrastim). Prescribing information. Amgen Inc.; 2021.
      </p>

      <p>
        Tai E, Guy GP, Dunbar A, Richardson LC. Cost of cancer-related
        neutropenia or fever hospitalizations, United States, 2012.{" "}
        <i>J Oncol Pract.</i> 2017;13(6):e552-e561.
      </p>
    </div>
  );
};

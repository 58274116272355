export const questions = [
  {
    question:
      'The Neutropenia Vulnerability Gap is the period that takes place within the first <span class="text-underlined">______</span> of Cycle 1 – when G-CSF is least effective.',
    options: ["24 hours", "Month", "14 days", "8 days"],
    correct: 3,
    reference:
      "Burris H, Belani CP, Kaufman PA, et al. <i>J Oncol Pract.</i> 2010;6(3):133-140. <br/>" +
      "Li Y, Klippel Z, Shih X, Wang H, Reinder M, Page JH. <i>Cancer Chemother Pharmacol.</i> 2016;77(4):703-712.",
  },
  {
    question:
      'During the Neutropenia Vulnerability Gap, G-CSFs can <span class="text-underlined">______________________</span>.',
    options: [
      "Leave patients unprotected from neutropenia",
      "Fully protect patients from neutropenia",
      "Lead to a spike in ANC",
      "Contribute to a significant drop in ANC",
    ],
    correct: 0,
    reference:
      "Neulasta (pegfilgrastim). Prescribing information. Amgen Inc.; 2021.",
  },
  {
    question:
      "Grade 4 neutropenia develops during the Neutropenia Vulnerability Gap when ANC falls below 0.5 and is associated with: ",
    options: ["Fever", "Infection", "Bacteremia", "All of the above"],
    correct: 3,
    reference:
      "Buckley SA, Othus M, Vainstein V, Abkowitz JL, Estey EH, Walter RB. <i>Am J Hematol.</i> 2014;89(4):423-428.",
  },
  {
    question:
      "To protect patients from neutropenia in the wake of the COVID-19 pandemic, U.S. guidelines recommend increasing the prophylactic use of G-CSF to include: ",
    options: [
      "High-risk patients only",
      "Intermediate-risk patients only",
      "Both high-risk and intermediate-risk patients",
      "The guidelines weren’t influenced",
    ],
    correct: 2,
    reference:
      "Centers for Disease Control and Prevention. Neutropenia and risk of infection. Accessed November 9, 2020.<br/>" +
      '<a href="https://www.cdc.gov/cancer/preventinfections/pdf/neutropenia.pdf" target="_blank" rel="noreferrer">https://www.cdc.gov/cancer/preventinfections/pdf/neutropenia.pdf</a>',
  },

  {
    question:
      "Approximately how many patients receive chemotherapy every year in the U.S.?",
    options: ["100,000", "650,000", "720,000", "1,000,000"],
    correct: 1,
    reference:
      "Halpern MT, Yabroff KR. <i>Cancer Invest.</i> 2008;26(6):647-651.",
  },

  {
    question:
      "When a patient’s absolute neutrophil count (ANC) decreases and approaches 1.0 or below, they run the risk of encountering life-threatening events, including:",
    options: [
      "Infection",
      "Febrile neutropenia (FN)",
      "Hospitalization",
      "All of the above",
    ],
    correct: 3,
    reference:
      "Crawford J, Dale DC, Lyman GH. <i>Cancer.</i> 2004;100(2):228-237.",
  },
  {
    question:
      "An unplanned disruption in chemotherapy treatment may result in:",
    options: [
      "Compromised outcomes",
      "Decreased overall survival",
      "No change in outcomes",
      "A and B",
    ],
    correct: 3,
    reference:
      "Lyman GH. <i>J Natl Compr Canc Netw.</i> 2009;7(1):99-108. <br/>" +
      "Crawford J, Denduluri N, Patt D, et al. <i>Support Care Cancer.</i> 2020;28(2):925-932.",
  },

  {
    question:
      'After receiving high-risk TAC chemotherapy, approximately <span class="text-underlined">___</span>% of patients on G-CSFs experienced Grade 4 neutropenia, which may lead to negative clinical consequences, including severe infection, fever, bacteremia, and death.',
    options: ["20%", "40%", "60%", "80%"],
    correct: 3,
    reference:
      "Bodey GP, Buckley M, Sathe YS, Freireich EJ. <i>Ann Intern Med.</i> 1966;64(2):328-340.<br/>" +
      "Buckley SA, Othus M, Vainstein V, Abkowitz JL, Estey EH, Walter RB. <i>Am J Hematol.</i> 2014;89(4):423-428<br/>" +
      "Lee J, Lee JE, Kim Z, et al. <i>Ann Surg Treat Res.</i> 2018;94(5):223-228.<br/>" +
      "Masuda N, Tokuda Y, Nakamura S, Shimazaki R, Ito Y, Tamura K. <i>Support Care Cancer</i>. 2015;23(10):2891-2898",
  },
  {
    question:
      "As G-CSF accelerates the maturation and proliferation of neutrophil precursors in bone marrow, the time it takes for neutrophils to mature leaves patients vulnerable to CIN and unprotected during which timeframe in Cycle 1?",
    options: ["Days 1-8", "Days 2-10", "Days 3-10", "Days 4-12"],
    correct: 0,
    reference:
      "Burris HA, Belani C, Kaufman PA, et al. <i>J Oncol Pract.</i> 2010;6(3):133-140.<br/>" +
      "Li Y, Klippel Z, Shih X, Wang H, Reiner M, Page JH. <i>Cancer Chemother Pharmacol.</i> 2016;77(4):703-712.",
  },
  {
    question:
      "Approximately how many CIN patients are hospitalized each year in the U.S.?",
    options: ["50,000", "80,000", "110,000", "210,000"],
    correct: 2,
    reference:
      "Tai E, Guy GP, Dunbar A, Richardson LC. <i>J Oncol Pract.</i> 2017;13(6):e552-e561.",
  },
  {
    question:
      "Approximately how long is the average hospital stay for CIN or CIN-related complications?",
    options: ["2.5 days", "5.1 days", "8.3 days", "10.7 days"],
    correct: 1,
    reference:
      "Healthcare Cost and Utilization Project (HCUP). 2017. Agency for Healthcare Research and Quality, Rockville, MD. Accessed November 13, 2020.</br>" +
      '<a href="https://hcupnet.ahrq.gov" target="_blank" rel="noreferrer">https://hcupnet.ahrq.gov</a>',
  },
  {
    question:
      "For a patient with CIN or CIN-related complications, a hospitalization stay could cost as much as:",
    options: ["$24,649", "$36,750", "$63,245", "$55,827"],
    correct: 2,
    reference:
      "<i>In a study of more than 300 SCLC patients from 2016-2019.</i><br/>" +
      "Epstein R, et al. 2020 ASCO Virtual Scientific Program. Abstract e19300.",
  },
  {
    question:
      "In a curative setting, which of the following is the greatest concern to patients with cancer who have been diagnosed with CIN?",
    options: [
      "Decreased dosage",
      "Delayed cycles",
      "Discontinued therapy",
      "Downgraded therapy",
    ],
    correct: 1,
    reference:
      "BeyondSpring, Inc. Buying Process/Patient Journey in CIN Market Research, November 2020.",
  },
  {
    question:
      "In a curative setting, which of the following is the greatest concern to oncologists when confronting CIN?",
    options: [
      "Decreased dosage",
      "Delayed cycles",
      "Discontinued therapy",
      "Downgraded therapy",
    ],
    correct: 0,
    reference:
      "BeyondSpring, Inc. Buying Process/Patient Journey in CIN Market Research, November 2020.",
  },
  {
    question:
      'Because it takes several days for G-CSFs to accelerate the maturation and proliferation of neutrophil precursors in bone marrow, their benefits are not seen until <span class="text-underlined">________</span> following chemotherapy.',
    options: ["Day 2", "Day 4", "Day 6", "Day 8"],
    correct: 3,
    reference:
      "Burris HA, Belani C, Kaufman PA, et al. <i>J Oncol Pract.</i> 2010;6(3):133-140.<br/>" +
      "Li Y, Klippel Z, Shih X, Wang H, Reiner M, Page JH. <i>Cancer Chemother Pharmacol.</i> 2016;77(4):703-712",
  },
];

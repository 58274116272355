import { Facebook, Twitter } from "react-social-sharing";
import badge1 from "../assets/images/badge-1.png";
import badge2 from "../assets/images/badge-2.png";
import badge3 from "../assets/images/badge-3.png";
import { useNavigation } from "../providers/NavigationProvider";
import { useQuiz } from "../providers/QuizProvider";
import { useUser } from "../providers/UserProvider";

const levels = [
  { badge: badge1, title: "CIN Snake Slayer" },
  { badge: badge2, title: "CIN Master Snake Slayer" },
  { badge: badge3, title: "CIN Ultimate Snake Slayer" },
];

export const Congrats = () => {
  const { sessionData, sessionScore, resetQuiz } = useQuiz();
  const { resetUser } = useUser();
  const { navigate } = useNavigation();

  const level = Math.round(sessionData.answers.length / 5);

  return (
    <div className="congrats">
      <div className="congrats-message">
        Congratulations!
        <br />
        You have reached {levels[level - 1].title}.
        <br />
        <span className="lead">Your score is {sessionScore}%</span>
      </div>
      <div className="congrats-badge">
        <img src={levels[level - 1].badge} alt={`Badge for phase ${level}`} />
      </div>
      {level !== 3 && (
        <div className="congrats-continue">
          <p>Would you like to continue?</p>
          <div className="form-item">
            <button
              type="button"
              onClick={() => navigate("quiz")}
              className="congrats-continue--yes"
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => navigate("intro")}
              className="congrats-continue--no"
            >
              No
            </button>
          </div>
        </div>
      )}
      {level === 3 && (
        <button
          type="button"
          onClick={() => {
            resetUser();
            resetQuiz();
            navigate("signin");
          }}
        >
          Retake Quiz
        </button>
      )}

      <div className="congrats-share">
        <span>Share on</span>
        <Facebook link={window.location.href} small />
        <Twitter
          link={window.location.href}
          message={`I earned a ${levels[level - 1].title} badge in the #CINSIGHTS quiz at the @BeyondSpringInc virtual booth at #SABCS20. Take the quiz and see how much you know about chemotherapy-induced neutropenia.`}
          small
        />
      </div>
    </div>
  );
};

import { createContext, useContext, useMemo, useState } from 'react';

export const NavigationContext = createContext({});

export const NavigationProvider = ({ children }) => {
  const [page, navigate] = useState('intro');

  const contextValue = useMemo(
    () => ({
      page,
      navigate,
    }),
    [page, navigate]
  );

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);

import { useForm } from "react-hook-form";
import { useNavigation } from "../providers/NavigationProvider";
import { useUser } from "../providers/UserProvider";

export const SignIn = () => {
  const { register, handleSubmit, errors, formState } = useForm();
  const { signIn } = useUser();
  const { navigate } = useNavigation();

  return (
    <>
      <p>
        Please provide your Email Address and User Name so you can see how you
        stack up against your peers on the Leaderboard.
      </p>
      <form
        onSubmit={handleSubmit(async (values) => {
          await signIn(values);
          navigate("quiz");
        })}
      >
        <div className="form-group">
          <div className="form-item">
            <label htmlFor="email">Email Address*</label>
            <input
              type="email"
              name="email"
              id="email"
              ref={register({
                required: true,
                pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              })}
            />
            {errors.email && (
              <div className="form-error">
                Please enter a valid email address
              </div>
            )}
          </div>

          <div className="form-item">
            <label htmlFor="username">User Name*</label>
            <input
              type="text"
              name="username"
              id="username"
              ref={register({ required: true })}
            />
            {errors.username && (
              <div className="form-error">
                This field is required
              </div>
            )}
          </div>
        </div>

        <div className="form-item">
          <button type="submit" disabled={formState.isSubmitting}>
            Sign In
          </button>
        </div>
      </form>
    </>
  );
};
